import BandImg from '@/images/insurances/band.jpg';
import CelebrationsImg from '@/images/insurances/celebrations-and-parties.jpg';
import ChristmasImg from '@/images/insurances/christmas-lights.jpg';
import ConferenceImg from '@/images/insurances/conference.jpg';
import ExhibitorsImg from '@/images/insurances/exhibitors.jpg';
import FestivalsImg from '@/images/insurances/festivals.jpg';
import FireworksImg from '@/images/insurances/fireworks.jpeg';
import HoleInOneImg from '@/images/insurances/hold-in-one.jpg';
import HotFoodImg from '@/images/insurances/hot-food.jpg';
import MultieventsImg from '@/images/insurances/multievents.jpg';
import oneOffImg from '@/images/insurances/one-off.jpg';
import RollTheDiceImg from '@/images/insurances/roll-the-dice.jpg';
import ShowsImg from '@/images/insurances/shows.jpg';
import SportsImg from '@/images/insurances/sports.jpg';
import StallholdersImg from '@/images/insurances/stallholders.jpg';
import StreetPartyImg from '@/images/insurances/street-party.jpg';

export const INSURANCE_TYPES = {
    "one-off-insurance": 'eis-shape-pink',
    "rule-builder-test-insurance": 'corporateBlue',
    "fireworks-insurance": 'eis-shape-indigo',
    "street-party-insurance": 'eis-shape-light-pink',
    "shows-insurance": 'eis-shape-blue',
    "festivals-insurance": 'eis-shape-light-green',
    "sporting-insurance": 'eis-shape-dark-orange',
    "sporting-event-insurance": 'eis-shape-dark-orange',
    "stallholders-insurance": 'eis-shape-green',
    "band-dj-and-performers": 'eis-shape-orange',
    "conferences-insurance": 'eis-shape-dark-blue',
    "multi-event-insurance": 'eis-shape-light-blue',
    "celebrations-parties": 'eis-shape-purple',
    "christmas-lights": 'eis-shape-red',
    "exhibitors": 'eis-shape-light-purple',
    "sporting-2-insurance": 'eis-shape-dark-orange',
    "hot-food-single": 'eis-shape-light-green',
    "hot-food-multi": 'eis-shape-light-green',
    "hole-in-one": 'eis-shape-yellow',
    "roll-a-dice": 'eis-shape-yellow',
};

export const INSURANCE_IMAGES = {
    "one-off-insurance": oneOffImg,
    "rule-builder-test-insurance": oneOffImg,
    "fireworks-insurance": FireworksImg,
    "street-party-insurance": StreetPartyImg,
    "shows-insurance": ShowsImg,
    "festivals-insurance": FestivalsImg,
    "sporting-insurance": SportsImg,
    "stallholders-insurance": StallholdersImg,
    "band-dj-and-performers": BandImg,
    "conferences-insurance": ConferenceImg,
    "multi-event-insurance": MultieventsImg,
    "celebrations-parties": CelebrationsImg,
    "christmas-lights": ChristmasImg,
    "exhibitors": ExhibitorsImg,
    "sporting-2-insurance": SportsImg,
    "hot-food-single": HotFoodImg,
    "hot-food-multi": HotFoodImg,
    "hole-in-one": HoleInOneImg,
    "roll-a-dice": RollTheDiceImg,
};
